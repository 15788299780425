import { differenceInDays, formatDistanceToNow, isFuture, isPast } from 'date-fns'
import { Token, Text } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import {
  FinalGrade,
  PerformanceSelector,
  PerformanceTimelineInterface,
  ReviewCategory,
  SingleTimelineEventInterface,
} from '@src/interfaces/performance'
import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'

export const gradeSettings = {
  [FinalGrade.Empty]: {
    title: 'N/A',
    color: Token.color.foreground,
    icon: undefined,
  },
  [FinalGrade.None]: {
    title: 'N/A',
    color: Token.color.foreground,
    icon: undefined,
  },
  [FinalGrade.Poor]: {
    title: 'Unsatisfactory',
    color: Token.color.warning,
    icon: 'ExclamationMark',
  },
  [FinalGrade.AverageMinus]: {
    title: 'Developing',
    color: Token.color.foreground,
    icon: 'TurboTransfer',
  },
  [FinalGrade.AveragePlus]: {
    title: 'Performing',
    color: Token.color.success,
    icon: 'SocialLike',
  },
  [FinalGrade.Strong]: {
    title: 'Exceeding',
    color: Token.color.success,
    icon: 'Trophy',
  },
  [FinalGrade.Exceptional]: {
    title: 'Exceptional',
    color: Token.color.purple,
    icon: 'Cleaning',
  },
} as const

export const getTimelineStepState = (
  startDate: string,
  endDate: string,
  category?: string,
) => {
  if (category === 'meeting') {
    return 'pending'
  }

  if (isFuture(new Date(startDate))) {
    return 'default'
  }

  if (isPast(new Date(endDate))) {
    return 'done'
  }

  return 'pending'
}

export const getDateDistance = (date: string) => {
  if (!date) {
    return <>-</>
  }

  const dayDiff = differenceInDays(new Date(date), new Date())
  return (
    <Text color={dayDiff <= 3 ? Token.color.orange : Token.color.greyTone50}>
      {`in ${formatDistanceToNow(new Date(date))}`}
    </Text>
  )
}

export const getPeriodCategoryBadgeContent = (
  periodCategory: ReviewCategory | ReviewCycleCategory,
  periodName: string | undefined,
) => {
  switch (periodCategory) {
    case ReviewCategory.PIP:
    case ReviewCategory.PIP_V2:
    case ReviewCycleCategory.PIP:
    case ReviewCycleCategory.PIP_V2:
      return 'PIP'
    case ReviewCategory.Probation:
    case ReviewCycleCategory.Probation:
      return 'P'
    default:
      return periodName?.split(' ')[0]
  }
}

export const getFormattedCycleNameForPendingCycle = (item: PerformanceSelector) => {
  if (item.category === ReviewCycleCategory.Performance) {
    return item.name || ''
  }

  if (
    item.category === ReviewCycleCategory.PIP ||
    item.category === ReviewCycleCategory.PIP_V2
  ) {
    return 'PIP'
  }

  return item.name || ''
}

export const getFormattedCycleName = (item: PerformanceTimelineInterface) => {
  if (item.category === ReviewCategory.Performance) {
    return item.cycle?.name || ''
  }

  if (item.category === ReviewCategory.PIP || item.category === ReviewCategory.PIP_V2) {
    return 'PIP'
  }

  return item.cycle?.name || ''
}

export const pickFormattedCycleName = (category: ReviewCategory, cycleName: string) => {
  if ([ReviewCategory.PIP, ReviewCategory.PIP_V2].includes(category)) {
    return 'PIP'
  }

  return cycleName
}

export const getTimelineStepDescription = (event: SingleTimelineEventInterface) => {
  if (event.category === 'meeting') {
    return (
      <>
        <Text use="p">{formatDate(event.start_period, 'dd MMM')}</Text>
      </>
    )
  }

  if (event.category === 'review_results') {
    if (isPast(new Date(event.start_period))) {
      return <Text>{`Closed ${formatDate(event.start_period, 'dd MMM')} `}</Text>
    }
    return (
      <>
        <Text use="p">{`Closes ${formatDate(event.start_period, 'dd MMM')}`}</Text>
      </>
    )
  }

  if (isPast(new Date(event.end_period))) {
    return <Text>{`Closed ${formatDate(event.end_period, 'dd MMM')} `}</Text>
  }

  if (isFuture(new Date(event.start_period))) {
    return <Text>{`Opens ${formatDate(event.start_period, 'dd MMM')} `}</Text>
  }

  if (
    event.category === 'kpi' ||
    event.category === 'review' ||
    event.category === 'promotion'
  ) {
    return (
      <>
        <Text use="p">{`Closes ${formatDate(event.end_period, 'dd MMM')}`}</Text>
      </>
    )
  }

  return <>-</>
}

export const canViewProgressionComponent = (data: EmployeeInterface) => {
  const senioritySublevel = data.specialisation_seniority_sublevel?.sublevel
  const maxSublevelToSeeProgression = 2
  return senioritySublevel && senioritySublevel <= maxSublevelToSeeProgression
}
