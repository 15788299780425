import { Flex, ProgressCircle, Token, Text } from '@revolut/ui-kit'
import React from 'react'
import { css } from 'styled-components'

interface Props {
  title: string
  subTitle: string
  color: string
  value: number
  progressText: string
  alignItems?: string
}

const ProgressCircleTextCss = css`
  font-size: 12px;
`

export const StepSection = ({
  title,
  subTitle,
  color,
  value,
  progressText,
  alignItems = 'center',
}: Props) => (
  <Flex gap="s-16" alignItems={alignItems}>
    <ProgressCircle
      bg={Token.color.greyTone8}
      color={color}
      strokeWidth={4}
      size={44}
      value={value}
    >
      <ProgressCircle.Text css={ProgressCircleTextCss} color={Token.color.foreground}>
        {progressText}
      </ProgressCircle.Text>
    </ProgressCircle>
    <Flex gap="s-2" flexDirection="column">
      <Text whiteSpace="nowrap" variant="emphasis1">
        {title}
      </Text>
      <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="caption">
        {subTitle}
      </Text>
    </Flex>
  </Flex>
)
