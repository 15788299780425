import React from 'react'
import { Flex, Progress } from '@revolut/ui-kit'
import {
  ColoredPercent,
  getPercentColor,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'

interface ProgressCellProps {
  value: number
}

export const ProgressCell = ({ value }: ProgressCellProps) => {
  const percent = value * 100
  const color = getPercentColor(percent)
  return (
    <Flex flexDirection="column" gap="s-4">
      <ColoredPercent percent={percent} alignSelf="end" />
      <Progress value={value} color={color} />
    </Flex>
  )
}
