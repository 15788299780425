import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import {
  Avatar,
  Box,
  Flex,
  Item,
  Token,
  VStack,
  Group,
  Text,
  Icon,
  Cell,
  TabBar,
  ItemSkeleton,
  ActionButton,
} from '@revolut/ui-kit'
import React, { useEffect, useMemo, useState } from 'react'

import { BarChart } from '@revolut/icons'
import {
  PerformanceSelector,
  PerformanceTimelineInterface,
  PerformanceTimelineStats,
  RequestFeedbackInterface,
  ReviewCategory,
} from '@src/interfaces/performance'
import {
  getPerformanceTimelineStats,
  useGetPerformanceSelector,
} from '@src/api/performance'
import { LayoutTabProps } from '@src/pages/EmployeeProfile/Layout/common/types'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { isBefore, subDays } from 'date-fns'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import {
  checkIsProbationPeriod,
  NewFlowRequestsResponse,
  useFetcherPerformanceRequests,
} from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useGetPeriodTypes } from '@src/utils/performance'
import { HeaderActions } from '@src/pages/EmployeeProfile/Layout/Performance/HeaderActions'
import Stat from '@components/Stat/Stat'
import { usePerformanceChartData } from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceHistory/hooks'

import {
  getPeriodCategoryBadgeContent,
  getFormattedCycleName,
  getFormattedCycleNameForPendingCycle,
  gradeSettings,
} from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { PerformanceSummaryHeader } from './PerfomanceSummaryHeader'
import { useGetPromotionProgression } from '@src/api/promotions'
import { usePerformanceSummaryData } from './usePerfomanceSummaryData'
import { usePerformanceTimelineTable } from '../../Preview/Performance/PerformanceHistory/hooks'
import { PerformanceLayoutCycle } from '../../Preview/Performance/Common/PerformanceLayoutCycle'
import PopupChart from '@src/components/Charts/BaseChart/PopupChart'
import { Chart } from '@src/components/Charts/EmployeePerformanceChart/EmployeePerformanceChart'
import { PerformanceLayoutKeyDates } from '../../Preview/Performance/PerformanceReview/PerformanceLayoutKeyDates'
import { ProbationPipTimeline } from '../../Preview/PerformanceSummary/ProbationPipTimeline'
import { PerformanceTimeline } from './PerformanceTimeline'
import { useGetPipCheckpoints } from '@src/api/pip'
import { useGetProbationCheckpoints } from '@src/api/probationReview'
import { PerformanceSummarySection } from './PerformanceSummarySection'
import { ProbationSummarySection } from './ProbationSummarySection'
import { useGetAllFeedbackList } from '@src/api/anytimeFeedback'
import { navigateTo } from '@src/actions/RouterActions'
import { PerformanceSenioritySublevel } from '@src/constants/performance'

enum Tabs {
  Cycles = 'cycles',
  Analytics = 'analytics',
}

export const NewPerformanceLayoutTab = ({ data, stats }: LayoutTabProps) => {
  const { query } = useQuery(true)
  const [timelineStats, setTimelineStats] = useState<PerformanceTimelineStats | null>()
  const [selectedPeriod, setSelectedPeriod] = useState<PerformanceSelector>()
  const [initialPeriod, setInitialPeriod] = useState<PerformanceSelector>()
  const [performanceSelector, setPerformanceSelector] = useState<PerformanceSelector[]>()
  const [performanceLink, setPerformanceLink] = useState<string>()

  const [navigationTabValue, setNavigationTabValue] = useState<Tabs | null>(Tabs.Cycles)
  const [selectedCycle, setSelectedCycle] = useState<PerformanceTimelineInterface>()

  const periodBeforeOngoing =
    selectedPeriod?.offset === 0
      ? performanceSelector?.find(el => el.offset === 1)
      : undefined

  const { data: performanceSelectorData } = useGetPerformanceSelector(data.id)
  const user = useSelector(selectUser)

  const pendingCycle = performanceSelectorData?.find(({ offset }) => offset === 0)

  const isProbationPeriod = checkIsProbationPeriod(data)
  const isPIPv2 = initialPeriod?.category === ReviewCycleCategory.PIP_V2

  const canViewAllPerformanceStats = !!data?.field_options?.permissions?.includes(
    PermissionTypes.CanViewAllPerformanceStats,
  )

  const { data: progression } = useGetPromotionProgression(data.id, selectedPeriod?.id)

  const { mappedChartData } = usePerformanceChartData(data, true)

  const performanceTimelineTable = usePerformanceTimelineTable(data)

  const { isNewProbation } = useGetPeriodTypes(initialPeriod)

  const { data: checkpointsData } = useGetProbationCheckpoints(
    isNewProbation ? data.id : null,
    initialPeriod?.id !== undefined ? String(initialPeriod?.id) : null,
  )
  const { data: pipCheckpointsData } = useGetPipCheckpoints(
    isPIPv2 ? data.id : null,
    initialPeriod?.id !== undefined ? String(initialPeriod?.id) : null,
  )

  const { data: feedback, refetch: refetchFeedbackList } = useGetAllFeedbackList(data.id)

  const checkpoints = useMemo(() => {
    if (isNewProbation && checkpointsData) {
      return checkpointsData
    }
    return pipCheckpointsData
  }, [checkpointsData, pipCheckpointsData])

  const isManager =
    data.line_manager?.id === user.id || data.quality_control?.id === user.id

  const initialCategory = useMemo(() => {
    if (isProbationPeriod) {
      return ReviewCategory.Probation
    }
    if (isPIPv2) {
      return ReviewCategory.PIP_V2
    }
    return ReviewCategory.Performance
  }, [isProbationPeriod, isPIPv2])

  const category = useMemo(() => {
    return selectedPeriod?.category === ReviewCycleCategory.Probation
      ? ReviewCategory.Probation
      : selectedPeriod?.category === ReviewCycleCategory.PIP
      ? ReviewCategory.PIP_V2
      : ReviewCategory.Performance
  }, [selectedPeriod?.category])

  const isProbationOrPip = category !== ReviewCategory.Performance

  const perfomanceSummaryProps = usePerformanceSummaryData({
    initialPeriod,
    progression,
    checkpoints,
    timelineStats,
  })

  const fetchPerformanceSelector = () => {
    let targetPeriod
    if (performanceSelectorData) {
      const selectors = performanceSelectorData.filter(selector => {
        if (selector.category === ReviewCycleCategory.PIP) {
          return true
        }
        if (selector.category === ReviewCycleCategory.Probation) {
          // if Probation is going to start in a week or less, we want to pre-fill cycle selector with it
          const startDateAdjusted = subDays(new Date(selector.start_date_time), 7)
          return isBefore(startDateAdjusted, new Date())
        }
        return isBefore(new Date(selector.start_date_time), new Date())
      })

      const changeSelectedPeriod =
        !!selectedPeriod && !selectors.find(selector => selector.id === selectedPeriod.id)

      if (isProbationPeriod || query.cycle_id === 'null') {
        targetPeriod = selectors.find(
          selector => selector.category === ReviewCycleCategory.Probation,
        )
      } else if ((!selectedPeriod || changeSelectedPeriod) && !isProbationPeriod) {
        if (query.cycle_id) {
          targetPeriod = selectors.find(selector => {
            return `${selector.id}` === query.cycle_id
          })
        } else {
          targetPeriod = selectors.find(
            selector => selector.performance_reviews_selected_cycle,
          )
        }
      }

      setInitialPeriod(targetPeriod)
      return selectors as OptionInterface[]
    }

    return []
  }

  useEffect(() => {
    const fetchSelector = () => {
      const result = fetchPerformanceSelector()
      setPerformanceSelector(result as PerformanceSelector[])
    }
    fetchSelector()
  }, [initialCategory, performanceSelectorData])

  useEffect(() => {
    if (isManager || canViewAllPerformanceStats) {
      getPerformanceTimelineStats(data.id)
        .then(response => setTimelineStats(response.data))
        .catch(() => setTimelineStats(null))
    }
  }, [isManager, canViewAllPerformanceStats])

  useEffect(() => {
    if (pendingCycle) {
      setSelectedPeriod(pendingCycle)
    }
  }, [pendingCycle])

  const { isNewFlow } = useGetPeriodTypes(selectedPeriod)

  const requestsContext = useFetcherPerformanceRequests({
    category: initialCategory,
    isNewFlow,
    id: data.id,
    performanceCycle: selectedPeriod,
  })
  const {
    data: requestsData,
    isLoading: fetchingRequests,
    refetch: refetchRequests,
  } = requestsContext

  function isNewFlowRequestsResponse(
    response: unknown,
  ): response is NewFlowRequestsResponse {
    return (
      response !== undefined &&
      response !== null &&
      typeof response === 'object' &&
      'results' in response
    )
  }

  function isRequestFeedbackInterfaceArray(
    response: unknown,
  ): response is RequestFeedbackInterface[] {
    return Array.isArray(response)
  }

  const renderPendingCycle = () => {
    const currentEmpoyeeSeniority = data.seniority
      ? `${data.seniority.name} ${PerformanceSenioritySublevel[data.seniority.level]}`
      : ''
    if (!pendingCycle) {
      return <></>
    }
    return (
      <Item
        use="button"
        variant="compact"
        aria-pressed={selectedPeriod?.id === pendingCycle.id}
        onClick={() => {
          setSelectedPeriod(pendingCycle)
          setSelectedCycle(undefined)
        }}
      >
        <Item.Avatar>
          <Avatar color={Token.color.foreground} size={40} textStyle="emphasis1">
            {selectedPeriod?.id === pendingCycle.id && (
              <Avatar.Badge
                bg={Token.color.foreground}
                position="bottom-right"
                useIcon={<Icon name="Check" size={12} />}
                size={16}
              />
            )}
            {getPeriodCategoryBadgeContent(pendingCycle.category, pendingCycle.name)}
          </Avatar>
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{currentEmpoyeeSeniority}</Item.Title>
          <Item.Description>
            {getFormattedCycleNameForPendingCycle(pendingCycle)}
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <Item.Value>
            <Text color={Token.color.greyTone50} variant="body1">
              Pending
            </Text>
          </Item.Value>
        </Item.Side>
      </Item>
    )
  }

  const requests = isNewFlow
    ? isNewFlowRequestsResponse(requestsData)
      ? requestsData.results
      : undefined
    : isRequestFeedbackInterfaceArray(requestsData)
    ? requestsData
    : undefined
  const chartIcon = <BarChart cursor="pointer" size={16} color={Token.color.greyTone50} />
  return (
    <VStack space="s-16" width="100%">
      <Cell>
        <VStack space="s-16" width="100%">
          <Flex flexDirection="row">
            <PerformanceSummaryHeader
              {...perfomanceSummaryProps}
              isLoading={!timelineStats}
            />
          </Flex>
          <HeaderActions
            selectedPeriod={selectedPeriod}
            data={data}
            requests={requests}
            fetchingRequests={fetchingRequests}
            checkpoints={checkpoints}
            refetchRequests={refetchRequests}
          />
        </VStack>
      </Cell>

      <TabBar
        onChange={selected => setNavigationTabValue(selected)}
        value={navigationTabValue}
      >
        <TabBar.Item to={Tabs.Cycles}>Your review cycles</TabBar.Item>
        <TabBar.Item to={Tabs.Analytics}>Analytics</TabBar.Item>
      </TabBar>

      {navigationTabValue === Tabs.Cycles && (
        <TwoColumnsLayout
          leftFlex={0}
          leftMinWidth={270}
          left={
            <Group>
              {performanceTimelineTable.table?.loading && (
                <>
                  <ItemSkeleton />
                  <ItemSkeleton />
                  <ItemSkeleton />
                </>
              )}
              {!performanceTimelineTable.table?.loading && renderPendingCycle()}
              {performanceTimelineTable.table?.data.map(item => (
                <Item
                  key={item.id}
                  use="button"
                  variant="compact"
                  aria-pressed={selectedPeriod?.id === item.cycle.id}
                  onClick={() => {
                    const clickedPeriod = performanceSelectorData?.find(
                      selector => selector.id === item.cycle.id,
                    )
                    if (clickedPeriod) {
                      setSelectedPeriod(clickedPeriod)
                    }
                    setSelectedCycle(item)
                  }}
                >
                  <Item.Avatar>
                    <Avatar
                      color={Token.color.foreground}
                      size={40}
                      textStyle="emphasis1"
                    >
                      {selectedPeriod?.id === item.cycle.id && (
                        <Avatar.Badge
                          bg={Token.color.foreground}
                          position="bottom-right"
                          useIcon={<Icon name="Check" size={12} />}
                          size={16}
                        />
                      )}
                      {getPeriodCategoryBadgeContent(item.category, item.cycle?.name)}
                    </Avatar>
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>
                      {item.employee_specialisation_seniority_sublevel?.name}
                    </Item.Title>
                    <Item.Description>{getFormattedCycleName(item)}</Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <Item.Value>
                      <Text color={gradeSettings[item.grade?.id]?.color} variant="body1">
                        {gradeSettings[item.grade?.id]?.title}
                      </Text>
                    </Item.Value>
                    {/* TODO will be added after changes on back-end */}
                    {/* <Item.Value>
                      <Flex alignItems="center">
                        <Icon
                          mr="s-4"
                          name="ArrowRates"
                          size={16}
                          color={Token.color.greyTone50}
                        />
                        <Text color={Token.color.greyTone50} fontSize={14}>
                          Calibrated
                        </Text>
                      </Flex>
                    </Item.Value> */}
                  </Item.Side>
                </Item>
              ))}
            </Group>
          }
          right={
            <Group>
              {performanceTimelineTable.table?.loading ? (
                <>
                  <ItemSkeleton />
                  <ItemSkeleton />
                  <ItemSkeleton />
                </>
              ) : (
                <>
                  <Flex gap="s-48" p="s-16" flexDirection="row">
                    <PerformanceLayoutCycle selectedPeriod={selectedPeriod} />
                    <Stat
                      label="Grade"
                      val={
                        <Flex alignItems="center">
                          <Box mr="s-8">
                            {selectedCycle ? (
                              <Text color={gradeSettings[selectedCycle.grade?.id]?.color}>
                                {gradeSettings[selectedCycle.grade?.id]?.title || 'N/A'}
                              </Text>
                            ) : (
                              <Text color={Token.color.greyTone50}>Pending</Text>
                            )}
                          </Box>
                          <PopupChart trigger={chartIcon}>
                            {() => (
                              <Chart
                                data={mappedChartData.grades}
                                id={data.id}
                                isRating={false}
                              />
                            )}
                          </PopupChart>
                        </Flex>
                      }
                      ml="s-32"
                    />
                  </Flex>
                  <Flex flexDirection="column">
                    <Text p="s-16" variant="emphasis1" color={Token.color.foreground}>
                      Timeline
                    </Text>
                    {!isNewFlow && !!selectedPeriod?.reviews.length && (
                      <Box p="s-16" pt={0}>
                        <PerformanceLayoutKeyDates reviews={selectedPeriod.reviews} />
                      </Box>
                    )}
                    {isNewFlow && selectedPeriod && (
                      <Box p="s-16" pt={0}>
                        {isProbationOrPip ? (
                          <ProbationPipTimeline
                            data={data}
                            finalGrade={selectedCycle?.grade?.id}
                            selectedPeriod={selectedPeriod}
                            performanceLink={performanceLink}
                          />
                        ) : (
                          <PerformanceTimeline
                            data={data}
                            finalGrade={selectedCycle?.grade?.id}
                            selectedPeriod={selectedPeriod}
                            performanceLink={performanceLink}
                          />
                        )}
                      </Box>
                    )}
                    <Box p="s-16" pt={0}>
                      <VStack space="s-16">
                        {selectedPeriod && (
                          <>
                            <Flex justifyContent="space-between">
                              <Flex alignItems="center" gap="s-16">
                                <Avatar useIcon="BarChart" />
                                <Text variant="emphasis1">Performance summary</Text>
                              </Flex>
                              {performanceLink && (
                                <ActionButton onClick={() => navigateTo(performanceLink)}>
                                  Detail View
                                </ActionButton>
                              )}
                            </Flex>
                            {selectedPeriod.category ===
                            ReviewCycleCategory.Performance ? (
                              <PerformanceSummarySection
                                data={data}
                                stats={stats}
                                selectedPeriod={selectedPeriod}
                                periodBeforeOngoing={periodBeforeOngoing}
                                setPerformanceLink={setPerformanceLink}
                                feedback={feedback}
                                refetchFeedbackList={refetchFeedbackList}
                              />
                            ) : (
                              <ProbationSummarySection
                                data={data}
                                selectedPeriod={selectedPeriod}
                                setPerformanceLink={setPerformanceLink}
                                feedback={feedback}
                                refetchFeedbackList={refetchFeedbackList}
                              />
                            )}
                          </>
                        )}
                      </VStack>
                    </Box>
                  </Flex>
                </>
              )}
            </Group>
          }
        />
      )}
    </VStack>
  )
}
