import { ProbationRecommendationInterface } from '@src/interfaces/probationReview'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import {
  Box,
  Flex,
  useTooltip,
  VStack,
  Token,
  ActionButton,
  IconName,
  Text,
  chain,
} from '@revolut/ui-kit'
import { GradedRequestInterface } from '@src/pages/Forms/Overview/Checkpoint/CheckpointReviewers'
import React, { ReactNode } from 'react'
import AvatarSnippet from '@components/AvatarSnippet/AvatarSnippet'
import { relationToString } from '@src/features/Scorecard/constants'
import { SingleTimelineEventReviewInterface } from '@src/interfaces/performance'
import { navigateTo } from '@src/actions/RouterActions'
import styled from 'styled-components'
import { Statuses } from '@src/interfaces'

const FlexWrap = styled(Flex)<{ isLink?: boolean }>`
  border-radius: ${Token.radius.r12};
  transition: all 0.3s;
  &:hover {
    background-color: ${props => (props.isLink ? Token.color.greyTone2 : 'inherit')};
  }
`

export const StepReviewersList = ({
  reviews,
  onClickAddReview,
  buttonDisabled,
  icon,
  title,
  performanceLink,
  subText,
  secondaryActionButton,
}: {
  reviews:
    | GradedRequestInterface[]
    | ProbationRecommendationInterface[]
    | SingleTimelineEventReviewInterface[]
  onClickAddReview: (e: React.MouseEvent) => void
  buttonDisabled: boolean
  icon: IconName
  title: string
  performanceLink?: string
  subText?: string
  secondaryActionButton?: ReactNode
}) => {
  const user = useSelector(selectUser)
  const tooltip = useTooltip()

  if (!reviews?.length) {
    return null
  }

  const getStatusSettings = (status?: Statuses) => {
    switch (status) {
      case Statuses.completed:
      case Statuses.completed_late:
        return {
          color: Token.color.foreground,
          title: 'Completed',
        }
      case Statuses.rejected:
        return { color: Token.color.red, title: 'Rejected' }
      case Statuses.expired:
        return { color: Token.color.greyTone50, title: 'Expired' }
      default:
        return { color: Token.color.greyTone50, title: 'Pending' }
    }
  }

  return (
    <Box>
      <VStack>
        {reviews.map(item => {
          const userIsReviewer = item.reviewer.id === user.id
          return (
            <FlexWrap
              key={item.id}
              alignItems="center"
              justifyContent="space-between"
              style={{ cursor: performanceLink ? 'pointer' : 'default' }}
              onClick={() => {
                if (performanceLink) {
                  navigateTo(`${performanceLink}?reviewer_id=${item.reviewer.id}`)
                }
              }}
              p="s-8"
              isLink={!!performanceLink}
            >
              <AvatarSnippet
                avatar={item.reviewer.avatar}
                status={item.status}
                fullName={relationToString(item.reviewer_relation, false)}
                text={chain(item.reviewer.full_name, `${subText || ''}`)}
                noItemWrap
              />
              {userIsReviewer && !buttonDisabled && !secondaryActionButton && (
                <ActionButton
                  useIcon={icon}
                  onClick={e => {
                    e.stopPropagation()
                    onClickAddReview(e)
                  }}
                  {...tooltip.getAnchorProps()}
                >
                  {title}
                </ActionButton>
              )}
              {userIsReviewer && !buttonDisabled && secondaryActionButton}
              {buttonDisabled && (
                <Text variant="body1" color={getStatusSettings(item.status)?.color}>
                  {getStatusSettings(item.status)?.title}
                </Text>
              )}
            </FlexWrap>
          )
        })}
      </VStack>
    </Box>
  )
}
