import { useMemo } from 'react'
import { format, isPast } from 'date-fns'
import {
  FinalGrade,
  PerformanceSelector,
  PerformanceTimelineStats,
} from '@src/interfaces/performance'
import { PromotionNominationTimelineInterface } from '@src/interfaces/promotions'
import { CheckpointType, ProbationCheckpoints } from '@src/interfaces/probationReview'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'

interface UsePerformanceSummaryProps {
  initialPeriod: PerformanceSelector | undefined
  progression: PromotionNominationTimelineInterface | undefined
  checkpoints: ProbationCheckpoints | undefined
  timelineStats: PerformanceTimelineStats | undefined | null
}

export const usePerformanceSummaryData = ({
  initialPeriod,
  progression,
  checkpoints,
  timelineStats,
}: UsePerformanceSummaryProps) => {
  return useMemo(() => {
    const probationPipEndDate = initialPeriod?.end_date_time
      ? format(new Date(initialPeriod?.end_date_time), 'd MMM yyyy')
      : ''
    const isProbationOrPipDecision = checkpoints
      ? checkpoints?.decision_checkpoints?.find(
          decisionCheckPoint =>
            decisionCheckPoint.checkpoint_type === CheckpointType.Decision &&
            decisionCheckPoint.decision,
        )
      : null
    const decisionCount = isProbationOrPipDecision ? 1 : 0

    const { reviewCheckPointsCount, passedCheckPointsCount } = (
      checkpoints?.checkpoints || []
    ).reduce(
      (acc, checkpoint) => {
        if (checkpoint.checkpoint_type === CheckpointType.Review) {
          acc.reviewCheckPointsCount += 1
          if (isPast(new Date(checkpoint.checkpoint_deadline))) {
            acc.passedCheckPointsCount += 1
          }
        }
        return acc
      },
      { reviewCheckPointsCount: 0, passedCheckPointsCount: 0 },
    )

    const grade = timelineStats?.latest_grade?.id || FinalGrade.None
    const periodName = timelineStats?.cycle.name || 'N/A'
    const isEligible = progression?.eligibility_criteria_result?.is_eligible
    const probationPipProps = {
      grade,
      periodCategory: initialPeriod?.category,
      periodName,
      firstStepTitle: 'Checkpoints',
      firstStepSubTitle: `${reviewCheckPointsCount} required`,
      firstStepCount: passedCheckPointsCount,
      firstStepRequiredCount: reviewCheckPointsCount,
      middleStepCount: decisionCount,
      middleStepRequiredCount: 1,
      middleStepTitle: 'Final decision',
      middleStepSubTitle: 'Result communicated',
    }

    if (initialPeriod?.category === ReviewCycleCategory.Probation) {
      return {
        ...probationPipProps,
        title: 'Probation',
        lastStepTitle: 'End of Probation',
        lastStepSubtitle: probationPipEndDate,
      }
    }

    if (
      initialPeriod?.category === ReviewCycleCategory.PIP ||
      initialPeriod?.category === ReviewCycleCategory.PIP_V2
    ) {
      return {
        ...probationPipProps,
        title: 'PIP',
        lastStepTitle: 'End of PIP',
        lastStepSubtitle: probationPipEndDate,
      }
    }

    return {
      grade,
      periodCategory: initialPeriod?.category,
      periodName,
      title: 'Progression',
      firstStepTitle: 'Review cycles',
      firstStepSubTitle: `${
        progression?.eligibility_criteria?.cycles_in_role_and_seniority || '0'
      } required`,
      firstStepCount:
        progression?.eligibility_criteria_result?.cycles_in_role_and_seniority || 0,
      firstStepRequiredCount:
        progression?.eligibility_criteria?.cycles_in_role_and_seniority || 0,
      middleStepTitle: 'Performing grades',
      middleStepSubTitle: `${
        progression?.eligibility_criteria?.strong_plus_grades_in_recent_cycles || '0'
      } required`,
      middleStepCount:
        progression?.eligibility_criteria_result?.strong_plus_grades_in_recent_cycles ||
        0,
      middleStepRequiredCount:
        progression?.eligibility_criteria?.strong_plus_grades_in_recent_cycles || 0,
      lastStepTitle: progression?.current_seniority?.name
        ? `${progression?.current_seniority?.name} → ${
            progression?.target_seniority?.name || ''
          }`
        : '',
      lastStepSubtitle: isEligible
        ? `Congrats, you will proceed to ${
            progression?.target_seniority?.name || ''
          } from ${progression?.eligibility_criteria_result.progression_cycle || ''}`
        : `On track for ${
            progression?.eligibility_criteria_result.progression_cycle || ''
          }`,
      isEligible,
    }
  }, [
    initialPeriod,
    progression,
    initialPeriod?.end_date_time,
    checkpoints,
    timelineStats?.latest_grade?.id,
  ])
}
