import map from 'lodash/map'
import { Id, IdAndName, Statuses } from './index'
import { AssessButtonTypes } from '@components/AssessButtons/AssessButtons'
import { AssessBehaviourButtonTypes } from '@components/AssessButtons/AssessBehaviourButtons'
import {
  ReviewCycleCategory,
  ReviewCyclesInterface,
  ReviewCycleStatus,
} from '@src/interfaces/reviewCycles'
import {
  EmployeeInterface,
  EmployeeOptionInterface,
  AvatarType,
  NameIdInterface,
  EmployeeBasicInterface,
} from '@src/interfaces/employees'
import { FunctionalDeliverableScorecardInterface } from '@src/interfaces/deliverables'
import { KpiInterface } from '@src/interfaces/kpis'
import { OptionInterface } from './selectors'
import { ProbationResults } from './probationReview'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { SeniorityInterface } from '@src/interfaces/seniority'
import {
  PerformanceChartCycles,
  PerformanceChartGradeData,
  PerformanceChartSingleData,
} from '@src/interfaces/chart'
import { PermissionTypes } from '@src/store/auth/types'
import { PerformanceScorecardQuestionTypeOptions } from '@src/interfaces/settings'
import { notReachable } from '@src/utils/notReachable'
import { Token } from '@revolut/ui-kit'
import { perfGradesCalculationConfig } from '@src/interfaces/performanceGrades'
import { ContributorType } from './talent/performance'
import { GradesMapInterface } from '@src/utils/grades'

export interface ExtraPermissionsInterface {
  permissions: PermissionTypes[]
  version: number
}

export interface PerformanceSelectorReview {
  submit_date?: string
}

export interface PerformanceSelector {
  id: number | string
  name: string
  offset: number
  requester_can_submit_review_v3: boolean
  requester_can_submit_upwards_review: boolean
  start_date_time: string
  scorecards_lock_date_time: string
  end_date_time: string
  review_period_start_day: string
  status?: ReviewCycleStatus
  hr_manager?: EmployeeInterface
  category: ReviewCycleCategory
  probation_version?: number
  reviews: PerformanceSelectorReview[]
  performance_reviews_selected_cycle?: boolean
  managers_publishing_day: string | null
  probation_reviews_deliverables_type?: 'kpi' | 'jira' | 'goal'
  version?: number
  extra_permissions_by_version?: ExtraPermissionsInterface[]
}

export interface ProbationCheckpointInterface {
  id: number
  date: string
  number: number
}

export interface PerformanceReviewerSelector {
  category: string
  created_date: string
  updated_date: string
  id: number
  name: string
  reviewer_name: string
  reviewer_id: string
  reviewer_relation: ReviewerRelation
}

export enum ReviewerRelation {
  Self = 'self',
  LineManager = 'line_manager',
  TeamMate = 'team_mate',
  FunctionalManager = 'functional_manager',
  Other = 'other',
  Committee = 'committee',
  HeadOfFunction = 'head_of_function',
  Unknown = '',
}

export enum ReviewType {
  Self = 'self',
  Other = 'other',
  LineManager = 'line_manager',
  FunctionalManager = 'functional_manager',
  TeamMate = 'team_mate',
  Upwards = 'upwards',
}

export const ReviewerRelationToShortTitle = {
  [ReviewerRelation.Self]: 'Self',
  [ReviewerRelation.LineManager]: 'LM',
  [ReviewerRelation.TeamMate]: 'Team Mate',
  [ReviewerRelation.FunctionalManager]: 'FM',
  [ReviewerRelation.Other]: 'Other',
  [ReviewerRelation.Committee]: 'Committee',
  [ReviewerRelation.HeadOfFunction]: 'HoF',
  [ReviewerRelation.Unknown]: '',
}

export const ReviewTypeToShortTitle = {
  [ReviewType.Self]: 'Self',
  [ReviewType.Other]: 'Other',
  [ReviewType.LineManager]: 'LM',
  [ReviewType.FunctionalManager]: 'FM',
  [ReviewType.TeamMate]: 'Team Mate',
  [ReviewType.Upwards]: 'Upwards',
}

export enum ReviewStatuses {
  completed = 'completed',
  pending = 'pending',
  draft = 'draft',
  rejected = 'rejected',
}

export enum SectionType {
  SingleChoice = 'single-choice',
  CriteriasAssessment = 'criterias-assessment',
}

export interface ReviewerInterface {
  id: number
  full_name?: string
  display_name?: string
  status?: string
  first_name?: string
  last_name?: string
  avatar?: AvatarType
  reviewer_relation?: ReviewerRelation
}

export interface RequestFeedbackInterface {
  id?: number
  reviewed_person: ReviewerInterface
  reviewed_by: ReviewerInterface
  requested_by: ReviewerInterface
  created_date?: string
  updated_date?: string
  cycle: {
    id: number | string
    name?: string
    start_date_time?: string
    end_date_time?: string
  } | null
  status?: Statuses
  category: string
  probation_checkpoint?: ProbationCheckpointInterface
  pip_cycle?: {
    id: number
    name?: string
    start_date_time?: string
    end_date_time?: string
  }
}

export interface PerfReviewRequestFeedbackInterface {
  id?: number
  reviewed_employee: ReviewerInterface
  reviewer: ReviewerInterface
  requester: ReviewerInterface
  created_date?: string
  cycle: {
    id: number
    name?: string
    start_date_time?: string
    end_date_time?: string
    category?: string
  } | null
  status?: Statuses
  reviewer_relation: ReviewerRelation
  updated_date: string
  probation_checkpoint?: ProbationCheckpointInterface
  pip_checkpoint?: ProbationCheckpointInterface
  category: ReviewCategory
}

export enum DeliverableOptions {
  DONT_KNOW = 'dont_know',
  POOR = 'poor',
  BASIC = 'basic',
  INTERMEDIATE = 'intermediate',
  ADVANCED = 'advanced',
  EXPERT = 'expert',
}

export enum CardTypes {
  companyValueCard = 'company-value-card',
  deliverableCriteriaCard = 'deliverables-criteria-card',
  skillCard = 'skill-card',
}

export interface ReviewViewInfo {
  id: number
  reviewer: EmployeeOptionInterface
  reviewer_relation: ReviewerRelation
  updated_date: string | null
  probation_checkpoint: number | null
}

export interface DeliverableSingleChoiceReview {
  value?: DeliverableOptions | null
  review: ReviewViewInfo
}

export interface DeliverableJustificationPreview {
  value?: DeliverableJustificationValue | null
  review: ReviewViewInfo
}

export interface DeliverableJustificationValue {
  comment: string | null
  reference_url: string | null
}

export interface PreviousDeliverablesValue {
  review_id: number
  value: DeliverableOptions
}

export interface PreviousCriteriaValue {
  review_id: number
  value: AssessButtonTypes | null
}

export interface PreviousBehaviourValue {
  review_id: number
  value: AssessBehaviourButtonTypes | null
}

export interface SingleChoice {
  type: SectionType.SingleChoice
  value?: DeliverableOptions | null
  values?: {
    value?: DeliverableOptions
    review: ReviewViewInfo
  }[]
  options: DeliverableOption[]
  previous_values?: PreviousDeliverablesValue[] | null
}

export interface CriteriaAssessmentValues {
  value: AssessButtonTypes | null
  review: ReviewInterface
}

export interface BehaviourAssessmentValues {
  value: AssessBehaviourButtonTypes | null
  review: ReviewInterface
}

export interface CriteriaAssessmentItem {
  text: string
  value?: AssessButtonTypes | null
  values?: CriteriaAssessmentValues[]
  previous_values?: PreviousCriteriaValue[] | null
  internal_data_id?: string
  internal_data_type?: string
}

export interface CriteriaAssessment {
  type?: SectionType.CriteriasAssessment
  level_key: string
  items?: CriteriaAssessmentItem[]
  internal_data_id?: string
  internal_data_type?: string
}

export interface BehaviourAssessmentItem {
  behaviour_id: number
  description: string
  justification: string | null
  justifications?: SummarySkillCardJustificationsInterface[]
  text: string
  value?: AssessBehaviourButtonTypes | null
  values?: BehaviourAssessmentValues[]
  previous_values?: PreviousBehaviourValue[] | null
}

export type ScorecardSections = CriteriaAssessment | SingleChoice

export interface PreviousDeliverableJustification {
  review_id: number
  value?: DeliverableJustificationValue[] | null
}

export interface PreviousSkillJustification {
  review_id: number
  value: string
}

export interface PreviousRating {
  review_id: number
  value: PerformanceRating
}

export interface PreviousReviewInterface {
  review: {
    id: number
    overall_feedback: OverallFeedbackInterface | null
  }
  cycle?: PerformanceCycleInterface
  checkpoint?: {
    checkpoint_date_time: string
    id: number
    number: number
  }
}

// TODO: separate skills and deliverables interfaces
export interface SkillCardInterface {
  criteria_key?: string
  skill_id?: number
  type: CardTypes
  questions?: string[]
  name: string
  rating?: PerformanceRating | null
  rating_expectation: DeliverableOptions | null
  justification?: string | null
  justifications?: (DeliverableSingleChoiceReview | DeliverableJustificationValue)[]
  sections: ScorecardSections[]
  previous_justifications?:
    | PreviousDeliverableJustification[]
    | PreviousSkillJustification[]
    | null
  previous_ratings?: PreviousRating[] | null
  sub_title?: string
  titleTooltip?: string
  playbook_url?: string
}

export interface ValueBasedCardInterface {
  description: string
  justification?: string | null
  justifications?: DeliverableSingleChoiceReview[]
  name: string
  playbook_url?: string | null
  rating: PerformanceRating | null
  sections: BehaviourAssessmentItem[]
  type: CardTypes
  value_id: number
}

export interface ScorecardError {
  justification?: string
  sections?: string[]
}

export interface DeliverableOption {
  key: DeliverableOptions
  text: string
}

export enum PerformanceReviewTypes {
  preview = 'preview',
  deliverables = 'deliverables',
  skills = 'skills',
  managerSkills = 'managerSkills',
  managerValuesSkills = 'managerValuesSkills',
  interviewScorecard = 'interview_scorecards',
  cultureFit = 'cultureFit',
  cultureValuesFit = 'cultureValuesFit',
  recommendation = 'recommendation',
  summary = 'summary',
  kpi = 'kpi',
}

export enum PerformanceRating {
  skipped = 'skipped',
  dont_know = 'dont_know',
  poor = 'poor',
  poor_plus = 'poor_plus',
  basic_minus = 'basic_minus',
  basic = 'basic',
  basic_plus = 'basic_plus',
  intermediate_minus = 'intermediate_minus',
  intermediate = 'intermediate',
  intermediate_plus = 'intermediate_plus',
  advanced_minus = 'advanced_minus',
  advanced = 'advanced',
  advanced_plus = 'advanced_plus',
  expert_minus = 'expert_minus',
  expert = 'expert',
  expert_plus = 'expert_plus',
  improvement_needed = 'improvement_needed',
  performing = 'performing',
  superpower = 'superpower',
}

// TODO: REVC-5947 - move grade calculation logic (at least config) to BE
export const getPerformanceRatingScore = (rating: PerformanceRating) => {
  switch (rating) {
    case PerformanceRating.improvement_needed:
      return perfGradesCalculationConfig.perf_rating_scores.improvement_needed
    case PerformanceRating.skipped:
    case PerformanceRating.dont_know:
    case PerformanceRating.performing:
      return perfGradesCalculationConfig.perf_rating_scores.dont_know
    case PerformanceRating.superpower:
    case PerformanceRating.poor:
      return perfGradesCalculationConfig.perf_rating_scores.poor
    case PerformanceRating.poor_plus:
      return perfGradesCalculationConfig.perf_rating_scores.poor_plus
    case PerformanceRating.basic_minus:
      return perfGradesCalculationConfig.perf_rating_scores.basic_minus
    case PerformanceRating.basic:
      return perfGradesCalculationConfig.perf_rating_scores.basic
    case PerformanceRating.basic_plus:
      return perfGradesCalculationConfig.perf_rating_scores.basic_plus
    case PerformanceRating.intermediate_minus:
      return perfGradesCalculationConfig.perf_rating_scores.intermediate_minus
    case PerformanceRating.intermediate:
      return perfGradesCalculationConfig.perf_rating_scores.intermediate
    case PerformanceRating.intermediate_plus:
      return perfGradesCalculationConfig.perf_rating_scores.intermediate_plus
    case PerformanceRating.advanced_minus:
      return perfGradesCalculationConfig.perf_rating_scores.advanced_minus
    case PerformanceRating.advanced:
      return perfGradesCalculationConfig.perf_rating_scores.advanced
    case PerformanceRating.advanced_plus:
      return perfGradesCalculationConfig.perf_rating_scores.advanced_plus
    case PerformanceRating.expert_minus:
      return perfGradesCalculationConfig.perf_rating_scores.expert_minus
    case PerformanceRating.expert:
    case PerformanceRating.expert_plus:
      return perfGradesCalculationConfig.perf_rating_scores.expert
    default:
      return notReachable(rating)
  }
}

export const getDeliverableOptionScore = (option: DeliverableOptions) => {
  switch (option) {
    case DeliverableOptions.DONT_KNOW:
      return 0
    case DeliverableOptions.POOR:
      return 1
    case DeliverableOptions.BASIC:
      return 2
    case DeliverableOptions.INTERMEDIATE:
      return 3
    case DeliverableOptions.ADVANCED:
      return 4
    case DeliverableOptions.EXPERT:
      return 5
    default:
      return notReachable(option)
  }
}

export enum FinalGrade {
  // BE requires '' as a null value, but the key for filtering has to be _empty
  None = '',
  Empty = '_empty',
  Poor = 'unsatisfactory',
  AverageMinus = 'average_minus',
  AveragePlus = 'average_plus',
  Strong = 'strong',
  Exceptional = 'exceptional',
}

export interface FinalGradeInterface {
  id: FinalGrade
  label: string
}

export enum KeeperGrade {
  No = 'no',
  ProbablyNo = 'probably_no',
  Yes = 'yes',
  StrongYes = 'strong_yes',
  YesAtAnyCost = 'yes_at_any_cost',
  Pass = 'pass',
  Fail = 'fail',
}

export const getFinalGradeScore = (option: FinalGrade) => {
  switch (option) {
    case FinalGrade.None:
    case FinalGrade.Empty:
    case FinalGrade.Poor:
      return 0
    case FinalGrade.AverageMinus:
      return 1
    case FinalGrade.AveragePlus:
      return 2
    case FinalGrade.Strong:
      return 3
    case FinalGrade.Exceptional:
      return 4
    default:
      return notReachable(option)
  }
}

export const deliverableOptionToFinalGrade = (rating: DeliverableOptions) => {
  switch (rating) {
    case DeliverableOptions.DONT_KNOW:
      return FinalGrade.Empty
    case DeliverableOptions.POOR:
      return FinalGrade.Poor
    case DeliverableOptions.BASIC:
      return FinalGrade.AverageMinus
    case DeliverableOptions.INTERMEDIATE:
      return FinalGrade.AveragePlus
    case DeliverableOptions.ADVANCED:
      return FinalGrade.Strong
    case DeliverableOptions.EXPERT:
      return FinalGrade.Exceptional
    default:
      return notReachable(rating)
  }
}

export const performanceRatingToFinalGrade = (rating: PerformanceRating) => {
  switch (rating) {
    case PerformanceRating.skipped:
    case PerformanceRating.dont_know:
      return FinalGrade.Empty
    case PerformanceRating.improvement_needed:
    case PerformanceRating.poor:
    case PerformanceRating.poor_plus:
      return FinalGrade.Poor
    case PerformanceRating.basic_minus:
    case PerformanceRating.basic:
    case PerformanceRating.basic_plus:
      return FinalGrade.AverageMinus
    case PerformanceRating.intermediate_minus:
    case PerformanceRating.intermediate:
    case PerformanceRating.intermediate_plus:
    case PerformanceRating.performing:
      return FinalGrade.AveragePlus
    case PerformanceRating.advanced_minus:
    case PerformanceRating.advanced:
    case PerformanceRating.advanced_plus:
    case PerformanceRating.superpower:
      return FinalGrade.Strong
    case PerformanceRating.expert_minus:
    case PerformanceRating.expert:
    case PerformanceRating.expert_plus:
      return FinalGrade.Exceptional
    default:
      return notReachable(rating)
  }
}

export const finalGradeToScore = (grade: FinalGrade | null) => {
  if (!grade) {
    return 0
  }
  switch (grade) {
    case FinalGrade.Empty:
      return perfGradesCalculationConfig.final_grade_scores.empty
    case FinalGrade.Poor:
      return perfGradesCalculationConfig.final_grade_scores.poor
    case FinalGrade.AverageMinus:
      return perfGradesCalculationConfig.final_grade_scores.average_minus
    case FinalGrade.AveragePlus:
      return perfGradesCalculationConfig.final_grade_scores.average_plus
    case FinalGrade.Strong:
      return perfGradesCalculationConfig.final_grade_scores.strong
    case FinalGrade.Exceptional:
      return perfGradesCalculationConfig.final_grade_scores.exceptional
    default:
      return notReachable(grade)
  }
}

export const scoreToFinalGrade = (score: number) => {
  if (score > perfGradesCalculationConfig.scores_to_grades_limits.exceptional) {
    return FinalGrade.Exceptional
  }
  if (score > perfGradesCalculationConfig.scores_to_grades_limits.strong) {
    return FinalGrade.Strong
  }
  if (score > perfGradesCalculationConfig.scores_to_grades_limits.average_plus) {
    return FinalGrade.AveragePlus
  }
  if (score > perfGradesCalculationConfig.scores_to_grades_limits.average_minus) {
    return FinalGrade.AverageMinus
  }
  return FinalGrade.Poor
}

export const getDeliverableOptionColors = (rating: DeliverableOptions) => {
  switch (rating) {
    case DeliverableOptions.DONT_KNOW:
      return { text: Token.color.foreground, background: Token.color.greyTone2 }
    case DeliverableOptions.POOR:
      return { text: Token.color.red, background: Token.color.red_10 }
    case DeliverableOptions.BASIC:
      return { text: Token.color.orange, background: Token.color.orange_10 }
    case DeliverableOptions.INTERMEDIATE:
      return { text: Token.color.foreground, background: Token.color.greyTone2 }
    case DeliverableOptions.ADVANCED:
      return { text: Token.color.teal, background: Token.color.teal_10 }
    case DeliverableOptions.EXPERT:
      return { text: Token.color.teal, background: Token.color.teal_10 }
    default:
      return notReachable(rating)
  }
}

export const getGradeColor = (grade?: FinalGrade) => {
  if (!grade) {
    return Token.color.red
  }

  switch (grade) {
    case FinalGrade.Empty:
      return Token.color.foreground
    case FinalGrade.Poor:
      return Token.color.red
    case FinalGrade.AverageMinus:
      return Token.color.orange
    case FinalGrade.AveragePlus:
      return Token.color.foreground
    case FinalGrade.Strong:
      return Token.color.teal
    case FinalGrade.Exceptional:
      return Token.color.teal
    default:
      return notReachable(grade)
  }
}

export const getBarRaiserGradeColor = (grade?: KeeperGrade | null) => {
  if (!grade) {
    return Token.color.red
  }

  switch (grade) {
    case KeeperGrade.Yes:
    case KeeperGrade.StrongYes:
    case KeeperGrade.YesAtAnyCost:
      return Token.color.success
    case KeeperGrade.Pass:
      return Token.color.foreground
    case KeeperGrade.ProbablyNo:
      return Token.color.warning
    case KeeperGrade.Fail:
    case KeeperGrade.No:
      return Token.color.danger
    default:
      return notReachable(grade)
  }
}

export const KeeperGradeToString = {
  [KeeperGrade.No]: 'No',
  [KeeperGrade.ProbablyNo]: 'Probably no',
  [KeeperGrade.Yes]: 'Yes',
  [KeeperGrade.StrongYes]: 'Strong yes',
  [KeeperGrade.YesAtAnyCost]: 'Yes at any cost',
  [KeeperGrade.Pass]: 'Pass',
  [KeeperGrade.Fail]: 'Fail',
}

export enum RankGrade {
  Top_10 = 'top_10',
  Top_25 = 'top_25',
  Top_50 = 'top_50',
  Bottom_50 = 'bottom_50',
}

export const RankGradeToString = {
  [RankGrade.Top_10]: 'In the top 10%',
  [RankGrade.Top_25]: 'In the top 25%',
  [RankGrade.Top_50]: 'In the top 50%',
  [RankGrade.Bottom_50]: 'In the bottom 50%',
}

export const FinalGradeToGraphNumber = {
  [FinalGrade.Empty]: 0,
  [FinalGrade.Poor]: 1,
  [FinalGrade.AverageMinus]: 2,
  [FinalGrade.AveragePlus]: 3,
  [FinalGrade.Strong]: 4,
  [FinalGrade.Exceptional]: 5,
}

export const PerformanceRatingToGraphNumber = {
  [PerformanceRating.skipped]: 0,
  [PerformanceRating.dont_know]: 0,
  [PerformanceRating.poor]: 1,
  [PerformanceRating.poor_plus]: 2,
  [PerformanceRating.basic_minus]: 3,
  [PerformanceRating.basic]: 4,
  [PerformanceRating.basic_plus]: 5,
  [PerformanceRating.intermediate_minus]: 6,
  [PerformanceRating.intermediate]: 7,
  [PerformanceRating.intermediate_plus]: 8,
  [PerformanceRating.advanced_minus]: 9,
  [PerformanceRating.advanced]: 10,
  [PerformanceRating.advanced_plus]: 11,
  [PerformanceRating.expert_minus]: 12,
  [PerformanceRating.expert]: 13,
  [PerformanceRating.expert_plus]: 14,
}

export const FinalGradeNumberToGrade = {
  1: FinalGrade.Poor,
  2: FinalGrade.AverageMinus,
  3: FinalGrade.AveragePlus,
  4: FinalGrade.Strong,
  5: FinalGrade.Exceptional,
}

export const FinalGradeNumberToTitle = {
  1: 'Unsatisfactory',
  2: 'Average -',
  3: 'Average +',
  4: 'Strong',
  5: 'Exceptional',
}

export const performanceRatingFromNumber = new Map(
  map(PerformanceRatingToGraphNumber, (value, key) => [value, key]),
)

export interface OverallFeedbackInterface {
  pros?: string[]
  cons?: string[]
}

export interface ReviewDataSectionInterface {
  cards?: SkillCardInterface[]
  justifications?: DeliverableJustificationValue[]
  rating: PerformanceRating | null
  skipped_section_justification?: string
  skipped_section_justifications?: SkippedJustificationsInterface[]
  rating_expectation?: PerformanceRating | null
  section_grade?: FinalGrade | null
}

export type AdditionalScorecardOption = { id: number; text: string }

export interface ReviewDataAdditionalScorecardsQuestionInterface {
  id?: number
  title?: string
  options?: AdditionalScorecardOption[]
  optional?: boolean
  placeholder_text?: string
  subtitle?: string
  question_type: PerformanceScorecardQuestionTypeOptions
  value: string | string[] | null
  answers?: AdditionalScorecardsQuestionAnswers
}

export type AdditionalScorecardsQuestionAnswers = {
  [answerRelation in ReviewerRelation]?: string | string[] | null
}

export interface ReviewDataAdditionalScorecardsInterface {
  id?: number
  title?: string
  description: string | null
  questions: ReviewDataAdditionalScorecardsQuestionInterface[]
}

export interface ReviewDataValueBasedSectionInterface
  extends Omit<ReviewDataSectionInterface, 'cards'> {
  cards?: ValueBasedCardInterface[]
}

export interface PerformanceKPISection {
  kpi_items: Partial<KpiInterface>[]
  rating: PerformanceRating
  recommended_rating: DeliverableOptions | null
  section_grade: FinalGrade | null
  rating_expectation?: PerformanceRating
  ratings?: Ratings[]
  comment: string | null
  comments?: SummarySkillCardJustificationInterface[]
  recommended_ratings?: SummarySkillCardJustificationInterface[]
  performance: number
}

export interface PerformanceRoadmapSection {
  roadmap_items: Partial<RoadmapInterface>[]
  rating: PerformanceRating
  ratings?: Ratings[]
  comment: string | null
  comments?: SummarySkillCardJustificationInterface[]
  performance: number
}

export interface ReviewCalculatedDeliverablesRatingsInterface {
  complexity: string
  quality: string
  rating: PerformanceRating
  recommended_rating: PerformanceRating
  recommended_rating_comment: string
  rating_expectation?: PerformanceRating
  speed: string
}

export interface ReviewDataInterface {
  overall_feedback?: OverallFeedbackInterface | null
  functional_skills?: ReviewDataSectionInterface
  manager_skills: ReviewDataSectionInterface | null
  manager_values?: ReviewDataValueBasedSectionInterface | null
  culture_skills: ReviewDataSectionInterface
  culture_values?: ReviewDataValueBasedSectionInterface | null
  deliverables?: ReviewDataSectionInterface | null
  cycle_sections?: ReviewDataAdditionalScorecardsInterface[] | null
  calculated_deliverables_ratings?: ReviewCalculatedDeliverablesRatingsInterface
  line_manager_extra_section?: ManagerRecommendationInterface
  functional_manager_extra_section?: ManagerRecommendationInterface
  peer_extra_section?: ManagerRecommendationInterface
  recommendation_extra_section?: ManagerRecommendationInterface
  extra_deliverables_overall_feedback?: DeliverablesOverallFeedbackInterface | null
  previous_reviews_by_cycle?: PreviousReviewInterface[]
  rating?: PerformanceRating | null
  rating_expectation?: PerformanceRating | null
  prefilled?: boolean
  kpis_section?: PerformanceKPISection
  roadmaps_section?: PerformanceRoadmapSection
  final_grade?: FinalGrade | null
  pip_extra_section?: ManagerRecommendationInterface
}

export interface ManagerRecommendationInterface {
  employee_project_performance: {
    name: string
    value: FinalGrade | null
    options: {
      key: FinalGrade
      items: string[]
    }[]
    justification: string | null
  }
  keeper_test_section: {
    name: string
    questions: KeeperQuestionInterface[]
  }
  pip_recommendation: PipQuestionInterface[]
  recommendation?: PipQuestionInterface[]
  rating: PerformanceRating
  relation?: string
  reviewer?: EmployeeOptionInterface
  checkpoint_number?: number
}

export interface KeeperQuestionInterface {
  name: string
  title?: string
  text?: string
  value: KeeperGrade | null
  options: {
    id: KeeperGrade
    name: string
  }[]
}

export interface PipQuestionInterface {
  name?: string
  options: {
    id: KeeperGrade
    name: string
  }[]
  text: string
  title: string
  value: KeeperGrade | null
}

export interface DeliverablesOverallFeedbackInterface {
  complexity: string[]
  quality: string[]
  speed: string[]
}

export enum ReviewCategory {
  Performance = 'performance',
  Upwards = 'upwards',
  Probation = 'probation',
  PIP = 'PIP',
  PIP_V2 = 'pip',
}

export interface ReviewedEmployeeInterface
  extends Pick<
    EmployeeInterface,
    | 'display_name'
    | 'full_name'
    | 'id'
    | 'seniority'
    | 'specialisation_seniority_sublevel'
    | 'specialisation'
  > {}

export interface PerformanceCheckpointInterface {
  id: number
  number: number
  cycle_id: number
}

export type GradeLabelMappingInterface = {
  [key in FinalGrade]: string
}

export interface ReviewScorecardInterface {
  id: number
  review_data: ReviewDataInterface
  reviewed_employee: ReviewedEmployeeInterface
  reviewer: EmployeeInterface
  cycle?: Id<string | number> & Partial<ReviewCyclesInterface>
  status: Statuses
  category: ReviewCategory
  visible_to_reviewee?: boolean | null
  current_user_can_edit_review?: boolean
  reviewer_relation?: ReviewerRelation
  probation_checkpoint?: ProbationCheckpointInterface | null
  performance_checkpoint?: PerformanceCheckpointInterface
  created_date?: string | null
  updated_date?: string | null
  isManagerReview?: boolean
  reviewed_employee_type: IdAndName<ContributorType>
  opened_date_time: string | null
  grade_label_mapping?: GradesMapInterface
  team?: NameIdInterface
}

export interface ReviewInterface {
  created_date?: string
  cycle?: ReviewCyclesInterface
  id: number
  reviewed_employee: EmployeeInterface
  reviewer:
    | EmployeeInterface
    | (EmployeeOptionInterface & { full_name: string; display_name: string })
  reviewer_relation: ReviewerRelation
  status?: Statuses
  updated_date: string
  category?: ReviewCategory
  probation_checkpoint?: ProbationCheckpointInterface | null
  performance_checkpoint?: PerformanceCheckpointInterface
  current_user_can_edit_review?: boolean
}

export interface ReviewViewDataInterface
  extends Omit<ReviewDataInterface, 'peer_extra_section'> {
  peer_extra_sections?: ManagerRecommendationInterface[]
}

export interface ReviewScorecardViewInterface {
  current_user_can_edit_review?: boolean
  summary: ReviewViewDataInterface
  grade_label_mapping: GradeLabelMappingInterface
  reviews: ReviewInterface[]
}

export interface Ratings {
  value: PerformanceRating
  review: ReviewInterface
}

export interface FinalGrades {
  value: FinalGrade
  review: ReviewInterface
}

export interface SkippedJustificationsInterface {
  value: string
  review: ReviewInterface
}

export interface SummarySkillCardInterface {
  name: string
  type?: string
  criteria_key?: string
  skill_id?: number
  rating: PerformanceRating | null
  rating_expectation: DeliverableOptions | null
  ratings?: Ratings[]
  justification?: SummarySkillCardJustificationInterface[]
  justifications?: SummarySkillCardJustificationsInterface[]
  sections: ScorecardSections[]
  previous_ratings?: PreviousRating[] | null
  previous_justifications?: PreviousSkillJustification[] | null
}

export interface SummarySkillCardJustificationInterface {
  value: string | null
  review: ReviewInterface
}

export interface SummarySkillCardJustificationsInterface {
  value: {
    comment: string
    reference_url: string | null
  }[]
  review: ReviewInterface
}

export interface ReviewSummaryOverallFeedbackInterface {
  value: OverallFeedbackInterface
  review: ReviewInterface
}

export interface SkillSummary {
  cards: SummarySkillCardInterface[]
  rating: PerformanceRating
  rating_expectation?: PerformanceRating
  ratings: Ratings[]
  skipped_section_justifications: SkippedJustificationsInterface[]
  justifications?: DeliverableJustificationPreview[]
}

interface SummaryCalculatedDeliverablesRating {
  rating: PerformanceRating
  ratings: Ratings[]
}

export interface SummaryCalculatedDeliverablesRatingsInterface {
  complexity: SummaryCalculatedDeliverablesRating | null
  quality: SummaryCalculatedDeliverablesRating | null
  rating: SummaryCalculatedDeliverablesRating | null
  recommended_rating?: SummaryCalculatedDeliverablesRating
  rating_expectation?: SummaryCalculatedDeliverablesRating
  recommended_rating_comment?: {
    ratings?: SummarySkillCardJustificationInterface[]
  }
  speed: SummaryCalculatedDeliverablesRating | null
}

export interface ReviewSummaryDataInterface {
  overall_feedbacks: ReviewSummaryOverallFeedbackInterface[]
  functional_skills?: SkillSummary
  culture_skills: SkillSummary
  culture_values?: SkillSummary
  manager_values?: SkillSummary
  manager_skills?: SkillSummary
  deliverables?: SkillSummary
  calculated_deliverables_ratings?: SummaryCalculatedDeliverablesRatingsInterface
  extra_deliverables_overall_feedback?: SummaryDeliverablesOverallFeedbackInterface[]
  ratings: Ratings[]
  rating: PerformanceRating
  final_grade?: FinalGrade
  final_grades?: FinalGrades[]
  line_manager_extra_section?: ManagerRecommendationInterface
  functional_manager_extra_section?: ManagerRecommendationInterface
  peer_extra_sections?: ManagerRecommendationInterface[]
  pip_extra_sections?: ManagerRecommendationInterface[]
  recommendation_extra_sections?: ManagerRecommendationInterface[]
  kpis_section?: PerformanceKPISection
  roadmaps_section?: PerformanceRoadmapSection
  cycle_sections?: ReviewDataAdditionalScorecardsInterface[] | null
}

export interface SummaryDeliverablesOverallFeedbackInterface {
  review: ReviewInterface
  value?: DeliverablesOverallFeedbackInterface | null
}

export interface ReviewSummaryInterface {
  grade_after_calibration?: CalibratedGradeInterface | null
  current_user_can_edit_review?: boolean
  summary: ReviewSummaryDataInterface | null
  grade_label_mapping: GradeLabelMappingInterface
  reviews: ReviewInterface[]
  team?: NameIdInterface
  reviewed_employee_type?: ContributorType
}

export interface ReviewNextInterface {
  category: 'performance' | 'probation'
  id: number
  reviewed_employee_id: number
}

export interface NotCompletedReviewInterface {
  id: number
  category: ReviewCategory
  status: Statuses
  reviewed_employee: ReviewerInterface
}

export interface NotCompletedReviewsInterface {
  pages: {
    next: number | null
    previous: number | null
    total: number
    page_size: number
  }
  count: number
  results: NotCompletedReviewInterface[]
}

export interface CalibratedGradeInterface {
  grade_calibrated: boolean
  display_grade: FinalGrade
  functional_manager?: EmployeeInterface
  functional_manager_grade: FinalGrade
  line_manager?: EmployeeInterface
  line_manager_grade: FinalGrade
  functional_manager_rating?: PerformanceRating
  line_manager_rating?: PerformanceRating
}

export interface ChangeScorecardInterface {
  cardIndex: number
  sectionIndex: number
  optionIndex?: number
  behaviourIndex?: number
  value: string
}

export interface PerformanceCycleInterface {
  id: string | number
  name: string
  offset?: number
  category: string
  start_date_time: string
  end_date_time: string
  status?: Statuses
  managers_publishing_day?: string
}

export interface CommentsPopupJustification {
  value: DeliverableJustificationValue[] | string | null
  review: ReviewInterface
}

export interface CommentsPopupDataInterface {
  name?: string
  rating?: PerformanceRating | null
  ratings?: Ratings[]
  justification?: CommentsPopupJustification[]
  justifications?: CommentsPopupJustification[]
}

export enum PerformanceSidebarManager {
  RequestOpened = 'RequestOpened',
  SummaryOpened = 'SummaryOpened',
  Closed = 'Closed',
}

export const RelationsCannotReject: ReviewerRelation[] = [
  ReviewerRelation.LineManager,
  ReviewerRelation.Self,
  ReviewerRelation.FunctionalManager,
]

export interface FunctionalCommentInterface {
  scorecard?: FunctionalDeliverableScorecardInterface
  ticketId: number
  ticketKey: string
}

export type PerformanceTimelineAction = 'view' | 'add' | 'edit' | null

type PerformanceTimelineParentType = 'cycle' | 'pip' | 'probation'

type PerformanceTimelineChildType = 'review' | 'decision' | 'recommendation'

export interface PerformanceTimelineInterface {
  id: number
  cycle: { id: number | string; name: string; probation_version: number }
  stage: string
  reviewers: EmployeeInterface[]
  employee_seniority: { id: number; name: string; level: number } | null
  employee_specialisation_seniority_sublevel?: { id: number; name: string } | null
  overall_feedback?: SkillsReviewsFeedbackInterface
  employee_specialisation: OptionInterface
  status: Statuses
  children?: PerformanceTimelineInterface[]
  action_type: PerformanceTimelineAction
  rating_label: string
  completion_date_time: string | null
  outcome: 'grade' | ProbationResults
  grade: FinalGradeInterface
  deliverables_rating: PerformanceRating | null
  skills_rating: PerformanceRating | null
  values_rating: PerformanceRating | null
  category: ReviewCategory
  scorecards_version: number | null
  scorecard: { id: number } | null
  manager_recommendation: { id: number } | null
  type: PerformanceTimelineParentType | PerformanceTimelineChildType
  probation_checkpoint?: { number: number }
}

export interface PerformanceTimelineStats {
  latest_deliverables_rating: PerformanceRating | null
  latest_grade: FinalGradeInterface | null
  latest_skills_rating: PerformanceRating | null
  latest_values_rating: PerformanceRating | null
  cycle: {
    name?: string
  }
}

export interface PerformanceTimelineChartSingleData extends PerformanceChartCycles {
  values: PerformanceChartSingleData[]
}

export interface PerformanceTimelineChartGradesData extends PerformanceChartCycles {
  values: PerformanceChartGradeData[]
}

export interface PerformanceTimelineChartInterface {
  grades: PerformanceTimelineChartGradesData
  deliverables: PerformanceTimelineChartSingleData
  skills: PerformanceTimelineChartSingleData
}

export interface ReviewersScoresInterface {
  rating: PerformanceRating
  reviewer_relation: ReviewerRelation
  reviewer_name: string
  note: string | null
}

export interface SkillsChildrenInterface
  extends Omit<SkillsReviewsInterface, 'children'> {}

export interface SkillsReviewsInterface {
  children: SkillsChildrenInterface[]
  cycle_category?: ReviewCategory
  cycle_id?: number
  cycle_name?: string
  notes?: string
  rating: PerformanceRating | null
  rating_expectation: PerformanceRating | null
  reviewers: EmployeeInterface[]
  reviewers_scores?: ReviewersScoresInterface[]
  seniority: SeniorityInterface
  skill: {
    id: number
    name: string
  }
}

export interface SkillsReviewsFeedbackInterface {
  pros: string[]
  cons: string[]
}

interface TimelineEventReviewerInterface {
  category: ReviewCategory
  cycle: IdAndName<string | number>
  id: number
  performance_checkpoint: number | null
  probation_checkpoint: number | null
  reviewed_employee: EmployeeOptionInterface
  reviewer: EmployeeOptionInterface
  status: ReviewStatuses
}

export interface SingleTimelineEventReviewInterface {
  id: number
  status: Statuses
  reviewer: ReviewerInterface
  reviewer_relation: ReviewerRelation
  can_submit?: boolean
}

export type PerformanceType =
  | 'review_results'
  | 'review'
  | 'goal'
  | 'kpi'
  | 'promotion'
  | 'meeting'

export interface SingleTimelineEventInterface {
  title?: string
  end_period: string
  start_period: string
  category?: PerformanceType
  type?: string
  link?: string
  reviewers?: TimelineEventReviewerInterface[]
  review_name?: string
  reviews?: SingleTimelineEventReviewInterface[]
}

export interface PerformanceTimelineEventsInterface {
  kpi_reviews: SingleTimelineEventInterface[]
  managers_meetings: SingleTimelineEventInterface[]
  reviews: SingleTimelineEventInterface[]
}

export interface PerformanceReviewInterface {
  id: number
  cycle: string
  reviewed_employee: EmployeeBasicInterface
  reviewer_relation: ReviewerRelation
  review_type?: ReviewType
  reviewer: EmployeeBasicInterface
  status: Statuses
  category: ReviewCycleCategory
  performance_checkpoint?: {
    checkpoint_date_time: string
    cycle_id: number
    id: number
    number: number
    employee_cycle_id?: string
    can_submit?: boolean
  }
  eligibility: {
    seniority: SeniorityInterface
    team: IdAndName
    role: IdAndName
    specialisation: IdAndName
  } | null
  seniority: SeniorityInterface
  role: IdAndName
  specialisation: IdAndName
  team: IdAndName
}

export interface PerformanceReviewStatInterface {
  status: Statuses
  amount: number
}

export type PerformanceReviewStats = Record<number, PerformanceReviewStatInterface>
