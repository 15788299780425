import { EmployeeInterface } from '@src/interfaces/employees'
import React, { useEffect, useState } from 'react'
import { tabsCategory } from '@components/AnytimeFeedback/AnytimeFeedbackSidebar'
import {
  AnytimeFeedbackListInterface,
  FeedbackCategory,
} from '@src/interfaces/anytimeFeedback'
import { Box, ErrorWidget, Flex, TabBar } from '@revolut/ui-kit'
import { AnytimeFeedbackCard } from '@components/AnytimeFeedback/AnytimeFeedbackCard'

interface Props {
  data: EmployeeInterface
  performanceContent?: React.ReactNode
  feedback?: AnytimeFeedbackListInterface
  refetchFeedbackList: () => void
  initialTabId?: tabsCategory
  scrollToNotes?: boolean
}

export const FeedbackSection = ({
  data,
  performanceContent,
  feedback,
  refetchFeedbackList,
  initialTabId = tabsCategory.Performance,
  scrollToNotes,
}: Props) => {
  const [currentTabId, setCurrentTabId] = useState<tabsCategory | null>(initialTabId)
  const feedbackList = feedback?.results || []

  useEffect(() => {
    if (scrollToNotes) {
      setCurrentTabId(tabsCategory.OneToOne)
    }
  }, [scrollToNotes])

  const tabs = [
    {
      title: 'Performance review',
      id: tabsCategory.Performance,
      list: [],
      type: FeedbackCategory.Performance,
    },
    {
      title: '1:1 notes',
      id: tabsCategory.OneToOne,
      list: feedbackList.filter(item => item.category === FeedbackCategory.OneToOne),
      type: FeedbackCategory.OneToOne,
    },
    {
      title: 'Anytime',
      id: tabsCategory.Anytime,
      list: feedbackList.filter(item => item.category === FeedbackCategory.Anytime),
      type: FeedbackCategory.Anytime,
    },
  ]

  const currentTab = tabs.find(tab => tab.id === currentTabId)
  const isPerformance = currentTab?.type === FeedbackCategory.Performance
  const hasPerformanceContent = isPerformance && !!performanceContent

  return (
    <Box>
      <Box mb="s-16">
        <Flex gap="s-8">
          <TabBar onChange={selected => setCurrentTabId(selected)} value={currentTabId}>
            {tabs.map(tab => (
              <TabBar.Item key={tab.id} to={tab.id}>
                {tab.title}
              </TabBar.Item>
            ))}
          </TabBar>
        </Flex>
      </Box>
      {isPerformance && performanceContent}
      {currentTab?.list.map(item => (
        <AnytimeFeedbackCard
          key={item.id}
          feedback={item}
          refreshFeedbackCard={refetchFeedbackList}
          employeeData={data}
          type={currentTab.type}
          withBorder
        />
      ))}
      {!hasPerformanceContent && !currentTab?.list.length && (
        <ErrorWidget>
          <ErrorWidget.Image src="https://assets.revolut.com/assets/3d-images/3D022.png" />
          <ErrorWidget.Title>Your feedback will appear here</ErrorWidget.Title>
        </ErrorWidget>
      )}
    </Box>
  )
}
